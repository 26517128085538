import React from "react"

import config from "../../../../../config.json"
import Page from "../../../../views/Partners/Money/Page"
import { WrappedBase } from "../../../../shared-components/Shared/WrappedBase"

const pageName = "partner-money-remortgage"

const View: React.FunctionComponent = () => (
  <WrappedBase
    metaTitle="Habito: Voted Best Mortgage Broker 2020 | Free Online Advice"
    metaDescription="We're the Treating Customers Fairly Champion of 2023 as voted for in the British Bank Awards. We're here to make mortgages easier - no paperwork, no stress and no fees."
    canonicalUrl="https://www.habito.com/partner/money/remortgage"
    noIndex={true}
    pageName={pageName}
    intercom={false}
    config={config}
  >
    <Page
      facts={[
        "Habito will make sure you’re eligible before you apply",
        "They’ll help you with your remortgage paperwork",
        "It’s all online, no hassle to you",
        "Chat to their friendly experts for advice and support",
      ]}
      href="/how-can-we-help/remortgage"
      pageName={pageName}
    />
  </WrappedBase>
)

export default View
